// @import 'kether-shared-lib/src/scss/styles/index';
@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/typography';

.p-border-right {
    border-right: 1px;
    border-right-style: solid;
    border-right-color: $border-default-color; // TODO : review
}

.p-summary-number {
    //@extend .p-bold;
    font-size: $size-2;
}

.p-si-legend {
    color: $grey-2;
}