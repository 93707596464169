@import '../scss/base/colors';
@import '../scss/base/borders';
@import '../scss/base/typography';

$size: 14px;

.p-dtbdd-wrapper {

    position: relative;

    .dropdown-container {

        @extend .p-border;
        position: absolute;
        background: $white;
        border-radius: 4px;
        box-shadow: 0 2px 16px rgba(#000000, 0.3);
        right: 0;
        margin-top: calc(#{$size} / 2);
        z-index: 60;

        .p-dropdown {
            &::before {
                position: absolute;
                content: '';
                top: calc(#{$size} / -2 + 1px); // Hide the margin
                right: calc((#{$size} * (1.4142 - 1)) / 2); // 0; // TODO
                width: $size;
                height: $size;
                transform: rotate(45deg);
                background: $white;
                @extend .p-border;
                border-right: none;
                border-bottom: none;
                border-radius: 2px 0 $size 0;
            }
        }
    }
}
