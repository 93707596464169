@import '../scss/base/typography';
@import '../scss/base/borders';

.p-topbar {

    margin: 0 !important;
    @extend .p-size-62;

    @media screen and (max-width: 768px) {
        padding-bottom: 10px;
        padding-top: 10px;
    }

    @extend .p-border-down;
}
