@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/background';
@import 'factor-lib/scss/base/layouts';
// @import 'factor-lib/colors';
// @import 'predict-lib/src/scss/base/colors';
//@import 'predict-lib/src/scss/base/typography';

.p-3-dots {
    // @extend .p-clickable; // TODO : discuss with Eric
    font-size: x-large;
}

// TODO : re-use icon
.euro {
    @extend .p-both-center;
    background-color: $green;
    color: $white;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 90%;
}

.buyerPaidText {
    color: $green; // $secondary;
    white-space: nowrap;
}

@media screen and (max-width: 1440px) {
    .p-il-table {
        td,
        th {
            &:not(:first-child) {
                padding-left: 0
            }
        }
    }
}


// Shared ?
.minSize {
    width: 1%;
}

.f-to-reverse {
    color: $warning-color; // $primary;
}
