@import 'factor-lib/scss/base/colors';

// TODO : why aren't they symmetric ?

$not-clickable-color: $grey; // #bbc6cd;

.p-arrow-up {
  border-style: solid;
  border-width: 0 6px 6px 6px;
  &.p-clickable {
    border-color: transparent transparent $green transparent;
  }
  &:not(.p-clickable) {
    border-color: transparent transparent $not-clickable-color transparent;
  }
}

.p-arrow-down {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  &.p-clickable {
    border-top: 6px solid $green;
  }
  &:not(.p-clickable) {
    border-top: 6px solid $not-clickable-color;
  }
}