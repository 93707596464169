@import 'factor-lib/scss/base/typography';
@import 'factor-lib/scss/base/borders';

// Taken from predict

.p-user-name {
    text-transform: uppercase;
}

.p-user-name:hover {
    color: rgba($text-color, 0.6);
}

.caret {
    display: inline-block;
    margin: auto 8px;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 4px solid $grey-2; // $divider-color;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: '';
    cursor: pointer;
    transition: 0.2s;
}

.caret:hover {
    border-top: 4px solid $border-default-color;
}
