@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/components/stepper';

.p-vse {
    position: relative;

    .p-vse-int {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
    }
}

.p-vse-line {
    position: absolute;
    width: 1px;
    background-color: $stepper-color;
    // Same length
    left: calc(#{$round-size} / 2);
    top: calc(#{$round-size} / 2);
}
