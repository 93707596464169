@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/typography';
@import 'factor-lib/scss/base/borders';

.p-nc-header {
    // TODO : looks a nice thing to re-use
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.p-card-title-separator {
    // box-sizing: border-box;
    height: 1px;
    // width: 705px;
    // border: 1px solid $white-6;
    background-color: $border-default-color;
}

.p-nc-content {
    background-color: $white;
}

.p-box-header {
    @extend .p-size-62;
    //@extend .p-border-down;
    //border-top-left-radius: $border-radius-small;
    //border-top-right-radius: $border-radius-small;
    background-color: #fafafa;
}