.p-menu-component {
    display: flex;
    flex-direction: row;

    .p-menu {
        align-items: stretch;
    }

    // https://stackoverflow.com/questions/35049290/force-a-flexbox-element-to-scroll-in-cross-axis-after-matching-other-elements-s

    .p-component-wrapper-relative {
        position: relative;

        .p-component-absolute {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
    
            // the infinite scroll component adds its own scrollbar that keeps popping in and out
            // even if we give an existing scrollable target. hide this scroll bar.
            // TODO: understand why and proper fix
            .infinite-scroll-component {
                overflow: hidden !important;
            }
        }
    }
}
