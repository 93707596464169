@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/borders';

// https://www.w3schools.com/css/tryit.asp?filename=trycss_tooltip

$size: 10px;

.p-ttp-wrapper {

    position: relative;

    .p-top-tooltip {
        // Cannot get it working
        // visibility: hidden;
        position: absolute;
        background-color: $white;
        box-shadow: -8px -3px 15px 0 $shadow-color;
        top: 0;
        // left: auto;
        // bottom: auto;
        right: 0;
        // bottom: 0;
        transform: translateY(calc(-100% - #{calc(#{$size} / 2)}));
        z-index: 5;
        // left: 110%; // TODO

        .p-arrow {
            &::after {
                @extend .p-border;
                position: absolute;
                content: '';
                bottom: calc(#{$size} / -2 - 1px);
                right: calc((#{$size} * (1.4142 - 1)) / 2);
                width: $size;
                height: $size;
                transform:  rotate(45deg);
                background: $white;
                border-left: none;
                border-top: none;
            }
        }
    }
}
