@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/components/input';
// @import 'predict-lib/src/scss/base/typography';

.p-sbm-menu {

    display: flex;
    justify-content: space-around;
    flex-direction: column;

    .p-button-wrapper {
        //@extend .p-legend-text;
        display: flex;
        flex-grow: 1;
        cursor: pointer;
        color: $grey-2;
        // text-align: left;
        //padding-left: 16px;

        .p-sbpm-icon {
            display: flex;
            align-items: center;

            height: 18px; // TODO : why not in the other way
            width: 20px; // TODO : why not in the other way
            filter: brightness(40%); // set svg color
        }
    }

    .p-button-wrapper.active {

        @extend .p-active-input;
        color: $green; // $secondary;

        .p-sbpm-icon {
            filter: brightness(100%); //set svg color
        }
    }

    .p-button-wrapper:hover:not(.active) {
        background: $bg-light-green; // $background-filter-color;
    }

    .p-button-animated {
        transition: 0.4s;
        display: flex;
        flex-direction: row;
    }

    .p-button-animated:not(:hover):not(.active) {
        padding-left: 0;
        padding-right: 16px;
    }

    .p-button-animated:hover:not(.active) {
        padding-left: 16px;
        padding-right: 0;
    }
}
