
@import 'factor-lib/scss/base/typography';
@import 'factor-lib/scss/base/colors';
//@import 'factor-lib/colors';

// Very duplicated with Predict, but complicated to generify with parameters for so little
// TODO : review, looks very long...
.logout {
  margin: 0 -16px;
  padding: 0 16px;
  height: 32px;
  line-height: 32px;
  // text-transform: uppercase; already set
  text-align: left;
  // @extend .p-size-default;
  font-weight: 800;
  color: $green;
  cursor: pointer;
  transition: 0.2s ease;
}

.logout:hover {
  color: rgba($green, 0.6);
}
