@import 'factor-lib/scss/base/typography';

// TODO: bulma has-text-right is not working
.has-text-right {
    text-align: right;
}

.amount-with-taxes {
    &--large {
        .amount-with-taxes {
            &__text-wi-tax {
                @extend .p-size-6;
                @extend .p-bold;
            }


            &__text-wo-tax {
                //@extend .p-size-default;
            }
        }
    }

    &--balanced {
        .amount-with-taxes {
            &__text-wi-tax {
                //@extend .p-size-default;
            }

            &__text-wo-tax {
                //@extend .p-size-default;
            }
        }
    }
}
