@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/borders';

.p-sidebar {
    background: $white;
    width: 200px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: $border-default-style $border-default-color; // TODO : shared ?
}
