@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/base/borders';

// https://www.w3schools.com/css/tryit.asp?filename=trycss_tooltip

$size: 10px;

.p-ttp-wrapper {

    position: relative;

    .p-bot-tooltip {
        // Cannot get it working
        // visibility: hidden;
        position: absolute;
        background-color: $white;
        box-shadow: -8px -3px 15px 0 $shadow-color;
        right: calc(-#{$size});
        //transform: translateY(#{$size * 4.5});
        z-index: 5;

        .p-arrow {
            &::after {
                @extend .p-border;
                position: absolute;
                content: '';
                top: calc(-#{$size} / 2);
                right: $size;
                width: $size;
                height: $size;
                transform:  rotate(45deg);
                background: $white;
                border-right: none;
                border-bottom: none;
            }
        }

        .p-tooltip-text {
            text-align: left;
        }
    }
}
