//@import '../scss/base/typography';
@import '../scss/base/borders';

.p-logo {
    // A complicated way to add a margin
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 128px;
    transition: 0.2s;
}

.p-logo:hover {
    // TODO : move general class ?
    opacity: 0.8;
}

.p-border-left {
    // TODO : is this shared ?
    border-left: $border-default-style $border-default-color;
}
